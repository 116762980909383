export const getParisMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-paris-route.jpg'),
            mapLine: 'ParisPath',
            venueBg:  require('@/assets/maps/paris.png'),
            venue: 'Paris',
            distance: '2.1',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
